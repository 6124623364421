import React, { useCallback, useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import axiosInstance, { getUserDetails } from "../../components/content/axiosConfig";
import { enqueueSnackbar } from "notistack";

const UpdatePD = () => {
  const history = useHistory();
  const { id } = useParams();
  const [ponds, setPonds] = useState([]);
  const fisherID = getUserDetails().FisherID;

  const [formData, setFormData] = useState({
    PondID: "0",
    Name: "",
    Date: new Date(),
    SamplingMethod: "",
    AirTemperature: 37,
    SkyClimate: "",
    WindSpeed: 0,
    MoonPhase: "",
    BaroMetricPressure: 0,
    WaterTemperature: 0,
    WaterClarity: "",
    PH: 0,
    DO: "",
    FertilizationDate: new Date(),
    FertilizationType: "",
    ForageBase: "",
    ForageStocking: "",
    WeedsPresent: "",
    FisherID: fisherID
  });

  const FetchPond = useCallback(async () => {
    try {
      const response = await axiosInstance.get(`api/Pond/GetAllPond/${fisherID}`, {
        params: { search: '' }
      });
      const users = response.data;
      setPonds(users);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        localStorage.removeItem("user");
        localStorage.removeItem("token");
        window.location.reload();
      }
      let errorMessage = "An error occurred.";
      if (error.response && error.response.data && error.response.data.message) {
        errorMessage = error.response.data.message;
      }
      enqueueSnackbar(errorMessage, {
        variant: 'error',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
        autoHideDuration: 3000,
      });
      console.error("Error fetching ponds:", error);
    }
  }, [fisherID]);

  const FetchPondDetail = useCallback(async () => {
    try {
      const response = await axiosInstance.get(`/api/PondDetail/GetPondDetail`, {
        params: { id, FisherID: fisherID }
      });

      const detail = response.data;

      setFormData({
        PondID: detail.PondID,
        Name: detail.Name,
        Date: new Date(detail.Date),
        SamplingMethod: detail.SamplingMethod,
        AirTemperature: detail.AirTemperature,
        SkyClimate: detail.SkyClimate,
        WindSpeed: detail.WindSpeed,
        MoonPhase: detail.MoonPhase,
        BaroMetricPressure: detail.BaroMetricPressure,
        WaterTemperature: detail.WaterTemperature,
        WaterClarity: detail.WaterClarity,
        PH: detail.PH,
        DO: detail.DO,
        FertilizationDate: new Date(detail.FertilizationDate),
        FertilizationType: detail.FertilizationType,
        ForageBase: detail.ForageBase,
        ForageStocking: detail.ForageStocking,
        WeedsPresent: detail.WeedsPresent,
        FisherID: fisherID
      }
      );

    } catch (error) {
      if (error.response && error.response.status === 401) {
        localStorage.removeItem("user");
        localStorage.removeItem("token");
        window.location.reload();
      }
      let errorMessage = "An error occurred.";
      if (error.response && error.response.data && error.response.data.message) {
        errorMessage = error.response.data.message;
      }
      enqueueSnackbar(errorMessage, {
        variant: 'error',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
        autoHideDuration: 3000,
      });
      console.error("Error fetching ponds:", error);
    }
  }, [fisherID, id]);

  useEffect(() => {
    FetchPond();
    FetchPondDetail();
  }, [FetchPond, FetchPondDetail]);



  const handleChange = (e) => {
    const { id, value, type, name } = e.target;
    console.log(formData);
    if (type === "radio") {
      setFormData({
        ...formData,
        [name]: value,
      });
    } else {
      setFormData({
        ...formData,
        [id]: value,
      });
    }
  };

  const handleDateChange = (e, type) => {
    const newDate = e.target.value;
    const currentDateTime = new Date();
    const [year, month, day] = newDate.split("-");
    currentDateTime.setFullYear(year);
    currentDateTime.setMonth(month - 1);
    currentDateTime.setDate(day);

    if (type === 1)
      setFormData({
        ...formData,
        Date: currentDateTime
      });
    else {
      setFormData({
        ...formData,
        FertilizationDate: currentDateTime
      });
    }
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    try {

      formData.FisherID = fisherID;
      await axiosInstance.put(`/api/PondDetail/UpdatePondDetail/${id}`, formData);
      history.push("/pondDetail");

    } catch (error) {
      console.error("Error creating Pond Detail:", error);
    }
  };
  return (
    <div>
      <form onSubmit={handleSubmit} className="fs-13px">
        <div className="d-flex align-items-center mb-3">
          <div>
            <ul className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/">Home</Link>
              </li>
              <li className="breadcrumb-item">
                <Link to="/staff">Pond Detail</Link>
              </li>
              <li className="breadcrumb-item active">Update Pond Detail</li>
            </ul>
            <h1 className="page-header mb-0">Update Pond  Detail</h1>
          </div>
          <div className="ms-auto">
            <button type="submit" className="btn btn-success btn-rounded px-4 rounded-pill">
              <i className="fa fa-plus fa-lg me-2 ms-n2 text-success-900"></i>
              Submit
            </button>
          </div>
        </div>
        <div className="card border-0">
          <div className="tab-content p-3">
            <div className="tab-pane fade show active" id="allTab">
              <div className="row">
                <div className="col-md-6">
                  {/* Pond Name */}
                  <div className="form-floating mb-15px">
                    <input
                      type="text"
                      className="form-control h-45px fs-13px"
                      placeholder="  Name"
                      id="Name"
                      value={formData.Name}
                      onChange={handleChange}
                    />
                    <label htmlFor="Name" className="d-flex align-items-center fs-13px text-gray-600">
                      Name
                    </label>
                  </div>
                </div>
                <div className="col-md-6">
                  {/* Name */}
                  <div className="form-floating mb-25px">

                    <select
                      className="form-control h-50px fs-13px"
                      id="PondID"
                      name="PondID"
                      value={formData.PondID}
                      onChange={handleChange}
                    >
                      <option value="">Select Pond</option>
                      {ponds.map((web) => (
                        <option value={web.id}>{web.Name}</option>
                      ))}
                    </select>
                    <label
                      htmlFor="PondID"
                      className="d-flex align-items-center fs-13px text-gray-600"
                    >
                      Pond &nbsp; <span>*</span>
                    </label>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  {/* Date */}
                  <div className="form-floating mb-15px">
                    <input
                      type="date"
                      className="form-control h-45px fs-13px"
                      placeholder="Date"
                      id="Date"
                      value={formData.Date.toISOString().substring(0, 10)}
                      onChange={(e) => { formData.Date = handleDateChange(e, 1) }}
                    />
                    <label htmlFor="Date" className="d-flex align-items-center fs-13px text-gray-600">
                      Date
                    </label>
                  </div>
                </div>
                <div className="col-md-6">
                  {/* Sampling Method */}
                  <div className="form-floating mb-15px">
                    <input
                      type="text"
                      className="form-control h-45px fs-13px"
                      placeholder="Sampling Method"
                      id="SamplingMethod"
                      value={formData.SamplingMethod}
                      onChange={handleChange}
                    />
                    <label htmlFor="SamplingMethod" className="d-flex align-items-center fs-13px text-gray-600">
                      Sampling Method
                    </label>
                  </div>
                </div>
              </div>

              <div className="row">

                <div className="col-md-6">

                  {/* Air Temperature */}
                  <div className="form-floating mb-15px">
                    <input
                      type="number"
                      className="form-control h-45px fs-13px"
                      placeholder="Air Temperature"
                      id="AirTemperature"
                      value={formData.AirTemperature}
                      onChange={handleChange}
                    />
                    <label htmlFor="AirTemperature" className="d-flex align-items-center fs-13px text-gray-600">
                      Air Temperature
                    </label>
                  </div>
                </div>

                <div className="col-md-6">
                  {/* Sky Climate */}
                  <div className="form-floating mb-15px">
                    <input
                      type="text"
                      className="form-control h-45px fs-13px"
                      placeholder="Sky Climate"
                      id="SkyClimate"
                      value={formData.SkyClimate}
                      onChange={handleChange}
                    />
                    <label htmlFor="SkyClimate" className="d-flex align-items-center fs-13px text-gray-600">
                      Sky Climate
                    </label>
                  </div>
                </div>
              </div>

              <div className="row">

                <div className="col-md-6">

                  {/* Wind Speed */}
                  <div className="form-floating mb-15px">
                    <input
                      type="number"
                      step={0.01}
                      className="form-control h-45px fs-13px"
                      placeholder="Wind Speed"
                      id="WindSpeed"
                      value={formData.WindSpeed}
                      onChange={handleChange}
                    />
                    <label htmlFor="WindSpeed" className="d-flex align-items-center fs-13px text-gray-600">
                      Wind Speed
                    </label>
                  </div>
                </div>

                <div className="col-md-6">
                  {/* Moon Phase */}
                  <div className="form-floating mb-15px">
                    <input
                      type="text"
                      className="form-control h-45px fs-13px"
                      placeholder="Moon Phase"
                      id="MoonPhase"
                      value={formData.MoonPhase}
                      onChange={handleChange}
                    />
                    <label htmlFor="MoonPhase" className="d-flex align-items-center fs-13px text-gray-600">
                      Moon Phase
                    </label>
                  </div>
                </div>

              </div>
              <div className="row">

                <div className="col-md-6">
                  {/* Barometric Pressure */}
                  <div className="form-floating mb-15px">
                    <input
                      type="number"
                      step={0.01}
                      className="form-control h-45px fs-13px"
                      placeholder="Barometric Pressure"
                      id="BaroMetricPressure"
                      value={formData.BaroMetricPressure}
                      onChange={handleChange}
                    />
                    <label htmlFor="BaroMetricPressure" className="d-flex align-items-center fs-13px text-gray-600">
                      Barometric Pressure
                    </label>
                  </div>


                </div>
                <div className="col-md-6">
                  {/* Water Temperature */}
                  <div className="form-floating mb-15px">
                    <input
                      type="number"
                      step={0.01}
                      className="form-control h-45px fs-13px"
                      placeholder="Water Temperature"
                      id="WaterTemperature"
                      value={formData.WaterTemperature}
                      onChange={handleChange}
                    />
                    <label htmlFor="WaterTemperature" className="d-flex align-items-center fs-13px text-gray-600">
                      Water Temperature
                    </label>
                  </div>
                </div>
              </div>
              <div className="row">

                <div className="col-md-6">

                  {/* Water Clarity */}
                  <div className="form-floating mb-15px">
                    <input
                      type="text"
                      className="form-control h-45px fs-13px"
                      placeholder="Water Clarity"
                      id="WaterClarity"
                      value={formData.WaterClarity}
                      onChange={handleChange}
                    />
                    <label htmlFor="WaterClarity" className="d-flex align-items-center fs-13px text-gray-600">
                      Water Clarity
                    </label>
                  </div>
                </div>

                <div className="col-md-6">
                  {/* PH */}
                  <div className="form-floating mb-15px">
                    <input
                      type="number"
                      step={0.1}
                      className="form-control h-45px fs-13px"
                      placeholder="PH"
                      id="PH"
                      value={formData.PH}
                      onChange={handleChange}
                    />
                    <label htmlFor="PH" className="d-flex align-items-center fs-13px text-gray-600">
                      PH
                    </label>
                  </div>
                </div>


              </div>
              <div className="row">

                <div className="col-md-6">
                  {/* DO */}
                  <div className="form-floating mb-15px">
                    <input
                      type="text"
                      className="form-control h-45px fs-13px"
                      placeholder="DO"
                      id="DO"
                      value={formData.DO}
                      onChange={handleChange}
                    />
                    <label htmlFor="DO" className="d-flex align-items-center fs-13px text-gray-600">
                      DO
                    </label>
                  </div>
                </div>
                <div className="col-md-6">
                  {/* Fertilization Date */}
                  <div className="form-floating mb-15px">
                    <input
                      type="date"
                      className="form-control h-45px fs-13px"
                      placeholder="Fertilization Date"
                      id="FertilizationDate"
                      value={formData.FertilizationDate.toISOString().substring(0, 10)}
                      onChange={(e) => { formData.FertilizationDate = handleDateChange(e, 2); }}
                    />
                    <label htmlFor="FertilizationDate" className="d-flex align-items-center fs-13px text-gray-600">
                      Fertilization Date
                    </label>
                  </div>
                </div>
              </div>


              <div className="row">

                <div className="col-md-6">
                  {/* Fertilization Type */}
                  <div className="form-floating mb-15px">
                    <input
                      type="text"
                      className="form-control h-45px fs-13px"
                      placeholder="Fertilization Type"
                      id="FertilizationType"
                      value={formData.FertilizationType}
                      onChange={handleChange}
                    />
                    <label htmlFor="FertilizationType" className="d-flex align-items-center fs-13px text-gray-600">
                      Fertilization Type
                    </label>
                  </div>
                </div>

                <div className="col-md-6">
                  {/* Forage Base */}
                  <div className="form-floating mb-15px">
                    <input
                      type="text"
                      className="form-control h-45px fs-13px"
                      placeholder="Forage Base"
                      id="ForageBase"
                      value={formData.ForageBase}
                      onChange={handleChange}
                    />
                    <label htmlFor="ForageBase" className="d-flex align-items-center fs-13px text-gray-600">
                      Forage Base
                    </label>
                  </div>
                </div>

              </div>

              <div className="row">

                <div className="col-md-6">

                  {/* Forage Stocking */}
                  <div className="form-floating mb-15px">
                    <input
                      type="text"
                      className="form-control h-45px fs-13px"
                      placeholder="Forage Stocking"
                      id="ForageStocking"
                      value={formData.ForageStocking}
                      onChange={handleChange}
                    />
                    <label htmlFor="ForageStocking" className="d-flex align-items-center fs-13px text-gray-600">
                      Forage Stocking
                    </label>
                  </div>
                </div>

                <div className="col-md-6">

                  {/* Weeds Present */}

                  <div className="form-floating mb-15px">
                    <input
                      type="text"
                      className="form-control h-45px fs-13px"
                      placeholder="Weeds Present"
                      id="WeedsPresent"
                      value={formData.WeedsPresent}
                      onChange={handleChange}
                    />
                    <label htmlFor="WeedsPresent" className="d-flex align-items-center fs-13px text-gray-600">
                      Weeds Present
                    </label>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </form>

    </div>
  );
};
export default UpdatePD;
