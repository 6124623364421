import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axiosInstance, { getUserDetails } from "../../components/content/axiosConfig";
import Modal from "react-modal";
import { enqueueSnackbar } from "notistack";
import Swal from 'sweetalert2';

const Sampling = () => {
  const [samples, setSamples] = useState([]);
  const [ponds, setPonds] = useState([]);
  const [fishTypes, setFishTypes] = useState([]);
  const fisherID = getUserDetails().FisherID;
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [entriesPerPage] = useState(10);

  const [pondName, setPondName] = useState('');
  const [fishType, setFishType] = useState(0);
  const [length, setLength] = useState(0);
  const [weight, setWeight] = useState(0);
  const [ws, setWs] = useState(0);
  const [wr, setWr] = useState(0);
  const [status, setStatus] = useState('Culled');

  const [isEditing, setIsEditing] = useState(false);
  const [editId, setEditId] = useState(null);

  const FetchSamples = useCallback(async () => {
    try {
      const response = await axiosInstance.get(`/api/FishSampling/GetAllSamples/${fisherID}`, {
        params: { search: searchTerm }
      });
      const pondNotes = response.data;
      setSamples(pondNotes);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        localStorage.removeItem("user");
        localStorage.removeItem("token");
        window.location.reload();
      }
      let errorMessage = "An error occurred.";
      if (error.response && error.response.data && error.response.data.message) {
        errorMessage = error.response.data.message;
      }
      enqueueSnackbar(errorMessage, {
        variant: 'error',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
        autoHideDuration: 3000,
      });
      console.error("Error fetching ponds:", error);
    }
  }, [fisherID, searchTerm]);


  const FetchPond = useCallback(async () => {
    try {
      const response = await axiosInstance.get(`api/Pond/GetAllPond/${fisherID}`, {
        params: { search: '' }
      });
      const users = response.data;
      setPonds(users);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        localStorage.removeItem("user");
        localStorage.removeItem("token");
        window.location.reload();
      }
      let errorMessage = "An error occurred.";
      if (error.response && error.response.data && error.response.data.message) {
        errorMessage = error.response.data.message;
      }
      enqueueSnackbar(errorMessage, {
        variant: 'error',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
        autoHideDuration: 3000,
      });
      console.error("Error fetching ponds:", error);
    }
  }, [fisherID]);


  const FetchFishTypes = useCallback(async () => {
    try {
      const response = await axiosInstance.get(`/api/FishTypes/GetAllFishTypes/${fisherID}`, {
        params: { search: '' }
      });
      const users = response.data;
      setFishTypes(users);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        localStorage.removeItem("user");
        localStorage.removeItem("token");
        window.location.reload();
      }
      let errorMessage = "An error occurred.";
      if (error.response && error.response.data && error.response.data.message) {
        errorMessage = error.response.data.message;
      }
      enqueueSnackbar(errorMessage, {
        variant: 'error',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
        autoHideDuration: 3000,
      });
      console.error("Error fetching ponds:", error);
    }
  }, [fisherID]);

  const CalculateWsWr = async () => {
    console.log("Function is Running");
    if (length > 0 && weight > 0 && fishType > 0) {
      console.log("Running ....");
      try {
        const response = await axiosInstance.get(`/api/FishTypes/GetFishType`, {
          params: { id: fishType }
        });
        const expression = response.data.Ws;
        if (length && expression) {
          const replacedExpression = expression.replace(/L/g, length);
          setWs(Function(`return ${replacedExpression}`)());

          setWr(parseFloat(weight / ws).toFixed(2));
          console.log(ws);
        } else {
          console.error("Invalid length or expression.");
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          localStorage.removeItem("user");
          localStorage.removeItem("token");
          window.location.reload();
        }
        let errorMessage = "An error occurred.";
        if (error.response && error.response.data && error.response.data.message) {
          errorMessage = error.response.data.message;
        }
        enqueueSnackbar(errorMessage, {
          variant: 'error',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          autoHideDuration: 3000,
        });
        console.error("Error fetching ponds:", error);
      }
    }
  }

  useEffect(() => {
    FetchPond();
    FetchFishTypes();
    FetchSamples();

  }, [FetchSamples, FetchFishTypes, FetchPond, searchTerm]);

  // Calculate the data for the current page
  const indexOfLastEntry = currentPage * entriesPerPage;
  const indexOfFirstEntry = indexOfLastEntry - entriesPerPage;
  const currentEntries = samples.slice(indexOfFirstEntry, indexOfLastEntry);
  const totalPages = Math.ceil(samples.length / entriesPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const DeleteUser = async (id) => {
    const result = await Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'Cancel',
    });

    if (result.isConfirmed) {
      try {
        await axiosInstance.delete(`/api/FishSampling/DeleteSample/${id}`);
        const updatedStaffs = samples.filter((staff) => staff.FishSamplingID !== id);
        setSamples(updatedStaffs);
      } catch (error) {
        if (error.response && error.response.status === 401) {
          localStorage.removeItem("user");
          localStorage.removeItem("token");
          window.location.reload();
        }
        console.error("Error fetching staff:", error);
      }
    }
  };

  const openModal = (data = null) => {
    if (data) {
      setIsEditing(true);
      setEditId(data.FishSamplingID);
      setPondName(data.PondID);
      setFishType(data.FishTypeID)
      setLength(data.Length);
      setWeight(data.Width);
      setWs(data.Ws);
      setWr(data.Wr);
      setStatus(data.Status);
    } else {
      setIsEditing(false);
      setEditId(null);
      setPondName("");
      setLength(0);
      setWeight(0);
      setWs(0);
      setWr(0);
      setStatus("Culled");
    }
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (isEditing) {
        await axiosInstance.put(`/api/FishSampling/UpdateSample/${editId}`, {
          Length: length,
          Width: weight,
          Ws: ws,
          Wr: wr,
          Status: status,
          PondID: pondName,
          FishTypeID: fishType,
          FisherID: fisherID
        });
      } else {
        await axiosInstance.post(`/api/FishSampling/AddSample`, {
          Length: length,
          Width: weight,
          Ws: ws,
          Wr: wr,
          Status: status,
          PondID: pondName,
          FishTypeID: fishType,
          FisherID: fisherID
        });
      }
      FetchSamples();
      closeModal();
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  return (
    <div>
      <div className="d-flex align-items-center mb-3">
        <div>
          <ul className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/">Home</Link>
            </li>
            <li className="breadcrumb-item Active"> Sampling </li>
          </ul>
          <h1 className="page-header mb-0"> Sampling</h1>
        </div>
        <div className="ms-auto">
          <button
            type="button"
            className="btn btn-success btn-rounded px-4 rounded-pill"
            onClick={() => openModal()}
          >
            <i className="fa fa-plus fa-lg me-2 ms-n2 text-success-900"></i>
            Add Sampling
          </button>
        </div>
      </div>
      <div className="card border-0">
        <div className="tab-content p-3">
          <div className="tab-pane fade show active" id="allTab">
            <div className="input-group mb-3">
              <div className="flex-fill position-relative">
                <div className="input-group">
                  <div
                    className="input-group-text position-absolute top-0 bottom-0 bg-none border-0 start-0"
                    style={{ zIndex: 10 }}
                  >
                    <i className="fa fa-search opacity-5"></i>
                  </div>
                  <input
                    type="text"
                    className="form-control px-35px bg-light"
                    placeholder="Search Sample..."
                    onChange={(e) => {
                      setSearchTerm(e.target.value);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="table-responsive mb-3">
              <table className="table table-hover table-panel text-nowrap align-middle mb-0">
                <thead>
                  <tr>
                    <th>Pond Name</th>
                    <th>Pond Fish Type </th>
                    <th>Length</th>
                    <th>Weight</th>
                    <th>Ws</th>
                    <th>Wr</th>
                    <th>Status</th>
                    <th>Actions </th>
                  </tr>
                </thead>
                <tbody>
                  {currentEntries.map((web) => (
                    <tr key={web.FishSamplingID}>
                      <td>{web.PondName}</td>
                      <td>{web.FishTypeName}</td>
                      <td>{web.Length}</td>
                      <td>{web.Width}</td>
                      <td>{web.Ws}</td>
                      <td>{web.Wr}</td>
                      <td>
                        {web.Status === "Culled" ? (
                          <span className="badge border border-success text-success px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center">
                            <i className="fa fa-circle fs-9px fa-fw me-5px"></i>{" "}
                            Culled
                          </span>
                        ) : (
                          <span className="badge border border-danger text-warning px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center">
                            <i className="fa fa-circle fs-9px fa-fw me-5px"></i>{" "}
                            Release
                          </span>
                        )}
                      </td>
                      <td>
                        <span onClick={() => openModal(web)}>
                          <i className="fa fa-pencil fs-13px fa-fw me-5px"></i>
                        </span>
                        &nbsp;&nbsp;
                        <span>
                          <i
                            onClick={() => DeleteUser(web.FishSamplingID)}
                            className="fa fa-trash fs-13px fa-fw me-5px"
                          ></i>
                        </span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="d-md-flex align-items-center">
              <div className="me-md-auto text-md-left text-center mb-2 mb-md-0">
                Showing{" "}
                {samples.length === 0
                  ? indexOfFirstEntry
                  : indexOfFirstEntry + 1}{" "}
                to{" "}
                {indexOfLastEntry > samples.length
                  ? samples.length
                  : indexOfLastEntry}{" "}
                of {samples.length} entries
              </div>
              <ul className="pagination mb-0 justify-content-center">
                <li
                  className={`page-item ${currentPage === 1 ? "disabled" : ""}`}
                >
                  <button
                    className="page-link"
                    onClick={() => handlePageChange(currentPage - 1)}
                  >
                    Previous
                  </button>
                </li>
                {[...Array(totalPages)].map((_, index) => (
                  <li
                    key={index}
                    className={`page-item ${index + 1 === currentPage ? "active" : ""
                      }`}
                  >
                    <button
                      className="page-link"
                      onClick={() => handlePageChange(index + 1)}
                    >
                      {index + 1}
                    </button>
                  </li>
                ))}
                <li
                  className={`page-item ${currentPage === totalPages ? "disabled" : ""
                    }`}
                >
                  <button
                    className="page-link"
                    onClick={() => handlePageChange(currentPage + 1)}
                  >
                    Next
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={{
          content: {
            width: "800px",
            height: "600px",
            margin: "auto",
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -20%)",
            padding: "20px",
          },
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          },
        }}
      >
        <form onSubmit={handleSubmit} className="fs-13px">
          <div className="d-flex align-items-center mb-3">
            <div>
              <h2> {isEditing ? "Edit Sample" : "Add Sample"}</h2>
            </div>
            <div className="ms-auto">
              <button
                type="submit"
                className="btn btn-success btn-rounded px-4 rounded-pill"
              >
                <i className="fa fa-plus fa-lg me-2 ms-n2 text-success-900"></i>
                Submit
              </button>
            </div>
          </div>
          <div className="card border-0">
            <div className="tab-content p-3">
              <div className="tab-pane fade show active" id="allTab">
                <div className="form-floating mb-25px">
                  <select
                    className="form-control h-50px fs-13px"
                    id="PondName"
                    name="PondName"
                    value={pondName}
                    onChange={(e) => { setPondName(e.target.value) }}
                  >
                    <option value="0">Select Pond</option>
                    {ponds.map((web) => (
                      <option value={web.id}>{web.Name}</option>
                    ))}
                  </select>
                  <label
                    htmlFor="PondName"
                    className="d-flex align-items-center fs-13px text-gray-600"
                  >
                    Pond &nbsp; <span>*</span>
                  </label>
                </div>

                <div className="form-floating mb-25px">
                  <select
                    className="form-control h-50px fs-13px"
                    id="FishType"
                    name="FishType"
                    value={fishType}
                    onChange={async (e) => { setFishType(e.target.value); await CalculateWsWr() }}
                  >
                    <option value="0">Select Fish Type</option>
                    {fishTypes.map((web) => (
                      <option value={web.id}>{web.Name}</option>
                    ))}
                  </select>
                  <label
                    htmlFor="FishType"
                    className="d-flex align-items-center fs-13px text-gray-600"
                  >
                    Fish Type &nbsp; <span>*</span>
                  </label>
                </div>


                <div className="form-floating mb-15px">
                  <input
                    type="number"
                    step={0.01}
                    className="form-control h-45px fs-13px"
                    placeholder="Length"
                    id="Length"
                    name="Length"
                    value={length}
                    onChange={async (e) => { setLength(e.target.value); await CalculateWsWr(); }}
                  />
                  <label
                    htmlFor="Length"
                    className="d-flex align-items-center fs-13px text-gray-600"
                  >
                    Length
                  </label>
                </div>

                <div className="form-floating mb-15px">
                  <input
                    type="number"
                    step={0.01}
                    className="form-control h-45px fs-13px"
                    placeholder="Weight"
                    id="Weight"
                    name="Weight"
                    value={weight}
                    onChange={async (e) => { setWeight(e.target.value); await CalculateWsWr(); }}
                  />
                  <label
                    htmlFor="Weight"
                    className="d-flex align-items-center fs-13px text-gray-600"
                  >
                    Weight
                  </label>
                </div>

                <div className="form-floating mb-15px">
                  <input
                    type="number"
                    step={0.01}
                    className="form-control h-45px fs-13px"
                    placeholder="Ws"
                    id="Ws"
                    name="Ws"
                    value={ws}
                    onChange={(e) => { setWs(e.target.value) }}
                  />
                  <label
                    htmlFor="Ws"
                    className="d-flex align-items-center fs-13px text-gray-600"
                  >
                    Ws
                  </label>
                </div>

                <div className="form-floating mb-15px">
                  <input
                    type="number"
                    step={0.01}
                    className="form-control h-45px fs-13px"
                    placeholder="Wr"
                    id="Wr"
                    name="Wr"
                    value={wr}
                    onChange={(e) => { setWr(e.target.value) }}
                  />
                  <label
                    htmlFor="Wr"
                    className="d-flex align-items-center fs-13px text-gray-600"
                  >
                    Wr
                  </label>
                </div>


                <div className="row">
                  <div className="col-md-12 d-flex align-items-center">
                    <div className="col-md-12 d-flex align-items-center">
                      <div className="form-check mb-15px me-3">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="Status"
                          id="Status"
                          value="Culled"
                          checked={status === "Culled"}
                          onChange={(e) => { setStatus("Culled") }}
                        />
                        <label className="form-check-label" htmlFor="isAdmin">
                          Culled
                        </label>
                      </div>
                      <div className="form-check mb-15px">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="Status"
                          id="Status"
                          value="Released"
                          checked={status === "Released"}
                          onChange={(e) => { setStatus("Released") }}
                        />
                        <label className="form-check-label" htmlFor="isUser">
                          Released
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </Modal>
    </div>
  );
};

export default Sampling;
