import React from "react";
import { Redirect } from "react-router-dom";
import Dashboard from "../pages/dashboard/dashboard.js";

import Staff from "../pages/staff/staff.js";
import UpdatePassword from "../pages/staff/changePassword.js";
import CreateStaff from "../pages/staff/createStaff.js";
import UpdateStaff from "../pages/staff/UpdateStaff.js";
import PondNote from "../pages/pondNote/pondNote.js";
import PondDetail from "../pages/pondDetail/pondDetail.js";
import CreatePondDetail from "../pages/pondDetail/CreadPD.js";
import UpdatePD from "../pages/pondDetail/UpdatePD.js";
import Pond from "../pages/pond/pond.js";
import CreatePond from "../pages/pond/createPond.js";
import UpdatePond from "../pages/pond/updatePond.js";
import Sampling from "../pages/sampling/sampling.js";
import FishTypes from "../pages/fishTypes/fishTypes.js";
import PondFishes from "../pages/pondFishes/pondFishes.js";

const routes = [
  {
    path: "/",
    exact: true,
    component: () => <Redirect to="/dashboard" />,
  },
  {
    path: "/dashboard",
    title: "Dashboard",
    component: () => <Dashboard />,
  },
  {
    path: "/pond",
    title: "Pond",
    component: () => <Pond />,
  },
  {
    path: "/createPond",
    title: "Create Pond",
    component: () => <CreatePond />,
  },
  {
    path: "/updatePond/:id",
    title: "Update Pond",
    component: () => <UpdatePond />,
  },
  {
    path: "/changePassword/:id",
    title: "Change Password",
    component: () => <UpdatePassword />,
  },
  {
    path: "/sampling",
    title: "Sampling",
    component: () => <Sampling />,
  },
  {
    path: "/fishTypes",
    title: "Fish Types",
    component: () => <FishTypes />,
  },
  {
    path: "/pondNote",
    title: "",
    component: () => <PondNote />,
  },
  {
    path: "/pondFishes",
    title: "pond Fishes",
    component: () => <PondFishes />,
  },
  
  {
    path: "/staff",
    title: "Users",
    component: () => <Staff />,
  },
  {
    path: "/createStaff",
    title: "Create Staff",
    component: () => <CreateStaff />,
  },
  {
    path: "/UpdateStaff/:id",
    title: "Update Staff",
    component: () => <UpdateStaff />,
  },
  {
    path: "/pondDetail",
    title: "Pond Detail",
    component: () => <PondDetail />,
  },
  {
    path: "/createpd",
    title: "Create Pond Detail",
    component: () => <CreatePondDetail />,
  },
  {
    path: "/Updatepd/:id",
    title: "Update Pond Detail",
    component: () => <UpdatePD />,
  },
  {
    path: "*",
    component: () => <Redirect to="/dashboard" />,
  },
];

export default routes;
