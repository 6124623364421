const Menu = [
  { path: "/dashboard", icon: "fa-solid fa-chart-line", title: "Dashboard" },
  { path: "/pond", icon: "fas fa-swimming-pool", title: "Pond" }, 
  { path: "/pondDetail", icon: "fas fa-swimming-pool", title: "Pond Details" },
  {
    path: "/fishTypes",
    icon: "fa-solid fa-fish",
    title: "Fish Types",
  },
  { path: "/pondFishes", icon: "fa-solid fa-fish", title: "Pond Fishes" },
  { path: "/sampling", icon: "fa-solid fa-binoculars", title: "Pond Sampling" },
  {
    path: "/pondNote",
    icon: "fa-regular fa-note-sticky",
    title: "Pond Note",
  },
  { path: "/staff", icon: "fa-solid fa-users", title: "Users" },
  // { path: "/Profile", icon: "fa-solid fa-users", title: "Profile" },
];

export default Menu;
