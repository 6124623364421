import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import axiosInstance, { getUserDetails } from "../../components/content/axiosConfig";
import { enqueueSnackbar } from "notistack";

const CreatePond = () => {
  const history = useHistory();

  const fisherID = getUserDetails().FisherID;

  const [formData, setFormData] = useState({
    Name: "",
    Location: "",
    Weight_Unit: "",
    Length_Unit: "",
    FisherID : 0
  });

  const handleChange = (e) => {
    const { id, value, type, checked, name } = e.target;
    if (type === "radio") {
      setFormData({
        ...formData,
        [name]: value === "true",
      });
    } else {
      setFormData({
        ...formData,
        [id]: type === "checkbox" ? checked : value,
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axiosInstance.post("api/Pond/AddPond", {
        Name: formData.Name,
        Location: formData.Location,
        Weight_Unit: formData.Weight_Unit,
        Length_Unit: formData.Length_Unit,
        FisherID : fisherID
      });
      history.push("/pond");
    } catch (error) {
      if (error.response && error.response.status === 401) {
        localStorage.removeItem("user");
        localStorage.removeItem("token");
        window.location.reload();
      }
      
      let errorMessage = "An error occurred.";
      if (error.response && error.response.data && error.response.data.message) {
        errorMessage = error.response.data.message;
      }

      enqueueSnackbar(errorMessage, {
        variant: 'error',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
        autoHideDuration: 3000,
      });
    }
  };
  return (
    <div>
      <form onSubmit={handleSubmit} className="fs-13px">
        <div className="d-flex align-items-center mb-3">
          <div>
            <ul className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/">Home</Link>
              </li>
              <li className="breadcrumb-item">
                <Link to="/pond"> Pond</Link>
              </li>
              <li className="breadcrumb-item Active"> Create Pond </li>
            </ul>
            <h1 className="page-header mb-0">Create Pond</h1>
          </div>
          <div className="ms-auto">
            <button
              type="submit"
              className="btn btn-success btn-rounded px-4 rounded-pill"
            >
              <i className="fa fa-plus fa-lg me-2 ms-n2 text-success-900"></i>
              Submit
            </button>
          </div>
        </div>
        <div className="card border-0">
          <div className="tab-content p-3">
            <div className="tab-pane fade show active" id="allTab">
              <div className="form-floating mb-15px">
                <input
                  type="text"
                  className="form-control h-45px fs-13px"
                  placeholder="Pond Name"
                  id="Name"
                  value={formData.Name}
                  onChange={handleChange}
                />
                <label
                  htmlFor="Name"
                  className="d-flex align-items-center fs-13px text-gray-600"
                >
                  Name
                </label>
              </div>

              <div className="form-floating mb-25px">
                <input
                  type="text"
                  className="form-control h-45px fs-13px"
                  placeholder="Location"
                  id="Location"
                  value={formData.Location}
                  onChange={handleChange}
                />
                <label
                  htmlFor="Location"
                  className="d-flex align-items-center fs-13px text-gray-600"
                >
                  Location
                </label>
              </div>

              <div className="form-floating mb-25px">
                <select
                  className="form-control h-50px fs-13px"
                  id="Weight_Unit"
                  name="Weight_Unit"
                  value={formData.Weight_Unit}
                  onChange={handleChange}
                >
                  <option value="">Select Weight Unit</option>
                  <option value="KG">KG</option>
                </select>
                <label
                  htmlFor="Weight_Unit"
                  className="d-flex align-items-center fs-13px text-gray-600"
                >
                  Weight Unit &nbsp; <span>*</span>
                </label>
              </div>

              <div className="form-floating mb-15px">
                <select
                  className="form-control h-45px fs-13px"
                  id="Length_Unit"
                  name="Length_Unit"
                  value={formData.Length_Unit}
                  onChange={handleChange}
                >
                  <option value="">Select Length Unit</option>
                  <option value="METER">METER</option>
                </select>
                <label
                  htmlFor="Length_Unit"
                  className="d-flex align-items-center fs-13px text-gray-600"
                >
                  Length Unit &nbsp; <span>*</span>
                </label>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default CreatePond;
