import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axiosInstance, { getUserDetails } from "../../components/content/axiosConfig";
import Modal from "react-modal";
import { enqueueSnackbar } from "notistack";
import Swal from 'sweetalert2';

const PondNote = () => {
	const [notes, setNotes] = useState([]);
	const [ponds, setPonds] = useState([]); 
	const [currentPage, setCurrentPage] = useState(1);
	const [modalIsOpen, setModalIsOpen] = useState(false);

	const [entriesPerPage] = useState(10);
	const [searchTerm, setSearchTerm] = useState('');
	const fisherID = getUserDetails().FisherID;
	const staffID = getUserDetails().id;

	const [modalData, setModalData] = useState({
		Note: "",
		PondID: 0,
		FisherID: fisherID,
		StaffID: staffID
	});

	const [isEditing, setIsEditing] = useState(false);
	const [editId, setEditId] = useState(null);

	const FetchPondNote = useCallback(async () => {
		try {
			const response = await axiosInstance.get(`/api/PondNote/GetAllNotes/${fisherID}`, {
				params: { search: searchTerm }
			});
			const pondNotes = response.data;
			setNotes(pondNotes);
			console.log("Here  is Notes : " + notes);
		} catch (error) {
			if (error.response && error.response.status === 401) {
				localStorage.removeItem("user");
				localStorage.removeItem("token");
				window.location.reload();
			}
			let errorMessage = "An error occurred.";
			if (error.response && error.response.data && error.response.data.message) {
				errorMessage = error.response.data.message;
			}
			enqueueSnackbar(errorMessage, {
				variant: 'error',
				anchorOrigin: {
					vertical: 'top',
					horizontal: 'right',
				},
				autoHideDuration: 3000,
			});
			console.error("Error fetching ponds:", error);
		}
	}, [fisherID, searchTerm]);


	const FetchPond = useCallback(async () => {
		try {
			const response = await axiosInstance.get(`api/Pond/GetAllPond/${fisherID}`, {
				params: { search: null }
			});
			const users = response.data;
			setPonds(users);
			console.log("Here is POnds : " + ponds)
		} catch (error) {
			if (error.response && error.response.status === 401) {
				localStorage.removeItem("user");
				localStorage.removeItem("token");
				window.location.reload();
			}
			let errorMessage = "An error occurred.";
			if (error.response && error.response.data && error.response.data.message) {
				errorMessage = error.response.data.message;
			}
			enqueueSnackbar(errorMessage, {
				variant: 'error',
				anchorOrigin: {
					vertical: 'top',
					horizontal: 'right',
				},
				autoHideDuration: 3000,
			});
			console.error("Error fetching ponds:", error);
		}
	}, [fisherID]);

	useEffect(() => {
		FetchPond();
		FetchPondNote();

	}, [FetchPondNote, FetchPond, searchTerm]);

	// Calculate the data for the current page
	const indexOfLastEntry = currentPage * entriesPerPage;
	const indexOfFirstEntry = indexOfLastEntry - entriesPerPage;
	const currentEntries = notes.slice(indexOfFirstEntry, indexOfLastEntry);
	const totalPages = Math.ceil(notes.length / entriesPerPage);

	const handlePageChange = (pageNumber) => {
		setCurrentPage(pageNumber);
	};

	const DeleteUser = async (id) => {

		const result = await Swal.fire({
			title: 'Are you sure?',
			text: "You won't be able to revert this!",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Yes, delete it!',
			cancelButtonText: 'Cancel',
		});

		if (result.isConfirmed) {
			try {
				await axiosInstance.delete(`/api/PondNote/DeleteNote/${id}`, {
					params: { FisherID: fisherID } // Pass the FisherID as a query parameter
				});
				const updatedStaffs = notes.filter((staff) => staff.PondNoteID !== id);
				setNotes(updatedStaffs);

			} catch (error) {
				if (error.response && error.response.status === 401) {
					localStorage.removeItem("user");
					localStorage.removeItem("token");
					window.location.reload();
				}
				let errorMessage = "An error occurred.";
				if (error.response && error.response.data && error.response.data.message) {
					errorMessage = error.response.data.message;
				}
				enqueueSnackbar(errorMessage, {
					variant: 'error',
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'right',
					},
					autoHideDuration: 3000,
				});
				console.error("Error fetching ponds:", error);
			}
		}
	};

	const openModal = (data = null) => {
		if (data) {
			setIsEditing(true);
			setEditId(data.PondNoteID);
			setModalData({
				Note: data.PondNote,
				PondID: data.PondID,
				FisherID: fisherID,
				StaffID: staffID
			});
		} else {
			setIsEditing(false);
			setEditId(null);
			setModalData({
				Note: "",
				PondID: 0,
				FisherID: fisherID,
				StaffID: staffID
			});
		}
		setModalIsOpen(true);
	};

	const closeModal = () => {
		setModalIsOpen(false);
	};

	const handleChange = (e) => {
		const { id, name, value, type, checked } = e.target;

		if (type === "radio") {
			setModalData({
				...modalData,
				[name]: value === "true",
			});
		} else {
			setModalData({
				...modalData,
				[id]: type === "checkbox" ? checked : value,
			});
		}
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		try {
			console.log(modalData);
			if (isEditing) {
				await axiosInstance.put(`/api/PondNote/UpdateNote/${editId}`, modalData);
			} else {
				await axiosInstance.post(`/api/PondNote/AddNote`, modalData);
			}
			FetchPondNote();
			closeModal();
		} catch (error) {
			if (error.response && error.response.status === 401) {
				localStorage.removeItem("user");
				localStorage.removeItem("token");
				window.location.reload();
			}
			let errorMessage = "An error occurred.";
			if (error.response && error.response.data && error.response.data.message) {
				errorMessage = error.response.data.message;
			}
			enqueueSnackbar(errorMessage, {
				variant: 'error',
				anchorOrigin: {
					vertical: 'top',
					horizontal: 'right',
				},
				autoHideDuration: 3000,
			});
			console.error("Error fetching ponds:", error);
		}
	};

	return (
		<div>
			<div className="d-flex align-items-center mb-3">
				<div>
					<ul className="breadcrumb">
						<li className="breadcrumb-item">
							<Link to="/">Home</Link>
						</li>
						<li className="breadcrumb-item Active">Add Pond Note </li>
					</ul>
					<h1 className="page-header mb-0"> Add Pond Note </h1>
				</div>
				<div className="ms-auto">
					<button
						type="button"
						className="btn btn-success btn-rounded px-4 rounded-pill"
						onClick={() => openModal()}
					>
						<i className="fa fa-plus fa-lg me-2 ms-n2 text-success-900"></i>
						Add Note
					</button>
				</div>
			</div>
			<div className="card border-0">
				<div className="tab-content p-3">
					<div className="tab-pane fade show active" id="allTab">
						<div className="input-group mb-3">
							<div className="flex-fill position-relative">
								<div className="input-group">
									<div
										className="input-group-text position-absolute top-0 bottom-0 bg-none border-0 start-0"
										style={{ zIndex: 10 }}
									>
										<i className="fa fa-search opacity-5"></i>
									</div>
									<input
										type="text"
										className="form-control px-35px bg-light"
										placeholder="Search Pond..."
										onChange={(e) => {
											setSearchTerm(e.target.value);
										}}
									/>
								</div>
							</div>
						</div>
						<div className="table-responsive mb-3">
							<table className="table table-hover table-panel text-nowrap align-middle mb-0">
								<thead>
									<tr>
										<th>Pond Name</th>
										<th>Note</th>
										<th>Update Date</th>
										<th>By Staff</th>
										<th>Actions </th>
									</tr>
								</thead>
								<tbody>
									{currentEntries.map((web) => (
										<tr key={web.PondNoteID}>
											<td>{web.PondName}</td>
											<td>{web.PondNote}</td>
											<td>{web.UpdatedAt}</td>
											<td>{web.StaffName}</td>
											<td>
												<span onClick={() => openModal(web)}>
													<i className="fa fa-pencil fs-13px fa-fw me-5px"></i>
												</span>
												&nbsp;&nbsp;
												<span>
													<i
														onClick={() => DeleteUser(web.PondNoteID)}
														className="fa fa-trash fs-13px fa-fw me-5px"
													></i>
												</span>
											</td>
										</tr>
									))}
								</tbody>
							</table>
						</div>
						<div className="d-md-flex align-items-center">
							<div className="me-md-auto text-md-left text-center mb-2 mb-md-0">
								Showing{" "}
								{notes.length === 0
									? indexOfFirstEntry
									: indexOfFirstEntry + 1}{" "}
								to{" "}
								{indexOfLastEntry > notes.length
									? notes.length
									: indexOfLastEntry}{" "}
								of {notes.length} entries
							</div>
							<ul className="pagination mb-0 justify-content-center">
								<li
									className={`page-item ${currentPage === 1 ? "disabled" : ""}`}
								>
									<button
										className="page-link"
										onClick={() => handlePageChange(currentPage - 1)}
									>
										Previous
									</button>
								</li>
								{[...Array(totalPages)].map((_, index) => (
									<li
										key={index}
										className={`page-item ${index + 1 === currentPage ? "active" : ""
											}`}
									>
										<button
											className="page-link"
											onClick={() => handlePageChange(index + 1)}
										>
											{index + 1}
										</button>
									</li>
								))}
								<li
									className={`page-item ${currentPage === totalPages ? "disabled" : ""
										}`}
								>
									<button
										className="page-link"
										onClick={() => handlePageChange(currentPage + 1)}
									>
										Next
									</button>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>

			<Modal
				isOpen={modalIsOpen}
				onRequestClose={closeModal}
				style={{
					content: {
						width: "800px",
						height: "400px",
						margin: "auto",
						position: "fixed",
						top: "50%",
						left: "50%",
						transform: "translate(-50%, -20%)",
						padding: "50px",
					},
					overlay: {
						backgroundColor: "rgba(0, 0, 0, 0.5)",
					},
				}}
			>
				<form onSubmit={handleSubmit} className="fs-13px">
					<div className="d-flex align-items-center mb-3">
						<div>
							<h2> {isEditing ? "Edit Pond Note" : "Add Pond Note"}</h2>
						</div>
						<div className="ms-auto">
							<button
								type="submit"
								className="btn btn-success btn-rounded px-4 rounded-pill"
							>
								<i className="fa fa-plus fa-lg me-2 ms-n2 text-success-900"></i>
								Submit
							</button>
						</div>
					</div>
					<div className="card border-0">
						<div className="tab-content p-3">
							<div className="tab-pane fade show active" id="allTab">

								<div className="form-floating mb-25px">

									<select
										className="form-control h-50px fs-13px"
										id="PondID"
										name="PondID"
										value={modalData.PondID}
										onChange={handleChange}
									>
										<option value="">Select Pond</option>
										{ponds.map((web) => (
											<option value={web.id}>{web.Name}</option>
										))}
									</select>
									<label
										htmlFor="PondID"
										className="d-flex align-items-center fs-13px text-gray-600"
									>
										Pond &nbsp; <span>*</span>
									</label>
								</div>

								<div className="form-floating mb-15px">
									<input
										type="text"
										className="form-control h-45px fs-13px"
										placeholder="Note"
										id="Note"
										name="Note"
										value={modalData.Note}
										onChange={handleChange}
									/>
									<label
										htmlFor="Note"
										className="d-flex align-items-center fs-13px text-gray-600"
									>
										Note &nbsp; <span>*</span>
									</label>
								</div>
							</div>
						</div>
					</div>
				</form>
			</Modal>
		</div>
	);
};

export default PondNote;
