import { enqueueSnackbar } from "notistack"

const ShowError = (ErroMessage) => {
    enqueueSnackbar(ErroMessage, {
        variant: 'error',
        anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
        },
        autoHideDuration: 3000,
    })
};

export default ShowError;

