import axios from 'axios';

let API_URL = "";

if (process.env.REACT_APP_Environment === 'development')
  API_URL = process.env.REACT_APP_API_URL;
else
  API_URL = process.env.REACT_APP_API_URL_Production;

const axiosInstance = axios.create({
  baseURL: API_URL,
});

axiosInstance.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});



  
export default axiosInstance;


export const getUserDetails = () => {
  const userString = localStorage.getItem("user");
  let user = {};

  if (userString) {
    try {
      user = JSON.parse(userString);
    } catch (error) {
      console.error("Error parsing user data:", error);
    }
  }

  return user;
};
