import React, { useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import axiosInstance from "../../components/content/axiosConfig";
import ShowError from "../../components/custom/custom";

const UpdatePassword = () => {
    const history = useHistory();
    const { id } = useParams();

    const [formData, setFormData] = useState({
        password: "",
        confirmPassword: "",
    });

    const handleChange = (e) => {
        const { id, value, type, checked, name } = e.target;
        if (type === "radio") {
            setFormData({
                ...formData,
                [name]: value === "true",
            });
        } else {
            setFormData({
                ...formData,
                [id]: type === "checkbox" ? checked : value,
            });
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            if (formData.password === formData.confirmPassword) {
                if (formData.password.length >= 8) {
                    await axiosInstance.post(
                        `api/User/UpdatePassword`,
                        {
                            password: formData.password,
                            confirmPassword: formData.confirmPassword,
                        },
                        {
                            params: { id: id },
                        }
                    );

                    history.push("/staff");
                }
                else {
                    ShowError("Please Agree with our terms and Conditions.")
                }

            }
            else {
                ShowError("Password should be Eight Characters long.")
            }
        } catch (error) {

            if (error.response && error.response.status === 401) {
                localStorage.removeItem("user");
                localStorage.removeItem("token");
                window.location.reload();
            }

            let errorMessage = "An error occurred.";
            if (error.response && error.response.data && error.response.data.message) {
                errorMessage = error.response.data.message;
            }

            ShowError(errorMessage)

            console.error("Error Deleting Pond :", error);
        }
    };
    return (
        <div>
            <form onSubmit={handleSubmit} className="fs-13px">
                <div className="d-flex align-items-center mb-3">
                    <div>
                        <ul className="breadcrumb">
                            <li className="breadcrumb-item">
                                <Link to="/">Home</Link>
                            </li>
                            <li className="breadcrumb-item">
                                <Link to="/staff"> Staff</Link>
                            </li>
                            <li className="breadcrumb-item Active">Change Password </li>
                        </ul>
                        <h1 className="page-header mb-0">Change Password</h1>
                    </div>
                    <div className="ms-auto">
                        <button
                            type="submit"
                            className="btn btn-success btn-rounded px-4 rounded-pill"
                        >
                            <i className="fa fa-plus fa-lg me-2 ms-n2 text-success-900"></i>
                            Submit
                        </button>
                    </div>
                </div>
                <div className="card border-0">
                    <ul className="nav nav-tabs nav-tabs-v2 px-3">
                        <li className="nav-item me-2">
                            <Link to={"/UpdateStaff/" + id} className="nav-link px-2">General</Link>
                        </li>
                        <li className="nav-item me-2">
                            <Link className="nav-link px-2 active">
                                Password
                            </Link>
                        </li>
                    </ul>
                    <div className="tab-content p-3">
                        <div className="tab-pane fade show active" id="allTab">
                            <div className="form-floating mb-15px">
                                <input
                                    type="password"
                                    className="form-control h-45px fs-13px"
                                    placeholder="Password "
                                    id="password"
                                    value={formData.password}
                                    onChange={handleChange}
                                />
                                <label
                                    htmlFor="password"
                                    className="d-flex align-items-center fs-13px text-gray-600"
                                >
                                    Password
                                </label>
                            </div>

                            <div className="form-floating mb-15px">
                                <input
                                    type="password"
                                    className="form-control h-45px fs-13px"
                                    placeholder="Confirm Password"
                                    id="confirmPassword"

                                    value={formData.confirmPassword}
                                    onChange={handleChange}
                                />
                                <label
                                    htmlFor="confirmPassword"
                                    className="d-flex align-items-center fs-13px text-gray-600"
                                >
                                    Confirm Password
                                </label>
                            </div>

                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default UpdatePassword;
