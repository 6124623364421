import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axiosInstance, { getUserDetails } from "../../components/content/axiosConfig";
import { enqueueSnackbar } from "notistack";
import Swal from "sweetalert2";

const PondDetail = () => {
  const [pondDetails, setPondDetails] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [entriesPerPage] = useState(10);
  const fisherID = getUserDetails().FisherID;

  const FetchPondDetail = useCallback(async () => {
    try {
      const response = await axiosInstance.get(`/api/PondDetail/GetAllDetails/${fisherID}`, {
        params: { search: searchTerm }
      });
      const pondNotes = response.data;
      setPondDetails(pondNotes);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        localStorage.removeItem("user");
        localStorage.removeItem("token");
        window.location.reload();
      }
      let errorMessage = "An error occurred.";
      if (error.response && error.response.data && error.response.data.message) {
        errorMessage = error.response.data.message;
      }
      enqueueSnackbar(errorMessage, {
        variant: 'error',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
        autoHideDuration: 3000,
      });
      console.error("Error fetching ponds:", error);
    }
  }, [fisherID, searchTerm]);

  useEffect(() => {
    FetchPondDetail();
  }, [FetchPondDetail, searchTerm]);


  function formatDateTime(isoString) {
    const date = new Date(isoString);

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");

    const formattedDate = `${year}-${month}-${day}`;
    const formattedTime = `${hours}:${minutes}:${seconds}`;

    return `${formattedDate} ${formattedTime}`;
  }

  const indexOfLastEntry = currentPage * entriesPerPage;
  const indexOfFirstEntry = indexOfLastEntry - entriesPerPage;
  const currentEntries = pondDetails.slice(indexOfFirstEntry, indexOfLastEntry);

  const totalPages = Math.ceil(pondDetails.length / entriesPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const DeletePond = async (id) => {
    const result = await Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'Cancel',
    });

    if (result.isConfirmed) {
      try {
        await axiosInstance.delete(`/api/PondDetail/DeletePondDetail/${id}`);
        const updatedPonds = pondDetails.filter((pond) => pond.id !== id);
        setPondDetails(updatedPonds);
      } catch (error) {
        if (error.response && error.response.status === 401) {
          localStorage.removeItem("user");
          localStorage.removeItem("token");
          window.location.reload();
        }

        let errorMessage = "An error occurred.";
        if (error.response && error.response.data && error.response.data.message) {
          errorMessage = error.response.data.message;
        }

        enqueueSnackbar(errorMessage, {
          variant: 'error',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          autoHideDuration: 3000,
        });

        console.error("Error Deleting Pond :", error);
      }
    }
  };

  return (
    <div>
      <div className="d-flex align-items-center mb-3">
        <div>
          <ul className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/">Home</Link>
            </li>
            <li className="breadcrumb-item active">Pond Details</li>
          </ul>
          <h1 className="page-header mb-0">Pond Details</h1>
        </div>
        <div className="ms-auto">
          <Link
            to="/createpd"
            className="btn btn-success btn-rounded px-4 rounded-pill"
          >
            <i className="fa fa-plus fa-lg me-2 ms-n2 text-success-900"></i>{" "}
            Add Pond Detail
          </Link>
        </div>
      </div>

      <div className="card border-0">
        <div className="tab-content p-3">
          <div className="tab-pane fade show active" id="allTab">
            <div className="input-group mb-3">
              <div className="flex-fill position-relative">
                <div className="input-group">
                  <div
                    className="input-group-text position-absolute top-0 bottom-0 bg-none border-0 start-0"
                    style={{ zIndex: 10 }}
                  >
                    <i className="fa fa-search opacity-5"></i>
                  </div>
                  <input
                    type="text"
                    className="form-control px-35px bg-light"
                    placeholder="Search Pond Detail..."
                    onChange={(e) => {
                      setSearchTerm(e.target.value);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="table-responsive mb-3">
              <table className="table table-hover table-panel text-nowrap align-middle mb-0">
                <thead>
                  <tr>
                    <th>Pond</th>
                    <th>Name</th>
                    <th>Air Tem</th>
                    <th>Sky Climate</th>
                    <th>Wind Speed</th>
                    <th>Moon Phase</th>
                    <th>BaroMetric Pressure </th>
                    <th>Forage Stocking </th>
                    <th>Updated At </th>
                    <th>Actions </th>
                  </tr>
                </thead>
                <tbody>
                  {currentEntries.map((report) => (
                    <tr key={report.id}>
                      <td>{report.PondName}</td>
                      <td>{report.Name}</td>
                      <td>{report.AirTemperature}</td>
                      <td>{report.SkyClimate}</td>
                      <td>{report.WindSpeed}</td>
                      <td>{report.MoonPhase}</td>
                      <td>{report.BaroMetricPressure}</td>
                      <td>{report.ForageStocking}</td>
                      <td>{formatDateTime(report.updatedAt)}</td>
                      <td>
                        <span>
                          <Link to={"/Updatepd/" + report.id}>
                            <i className="fa fa-pencil fs-13px fa-fw me-5px"></i>
                          </Link>
                        </span>

                        &nbsp;&nbsp;
                        <span>
                          <i
                            onClick={() => DeletePond(report.id)}
                            className="fa fa-trash fs-13px fa-fw me-5px"
                          ></i>
                        </span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            <div className="d-md-flex align-items-center">
              <div className="me-md-auto text-md-left text-center mb-2 mb-md-0">
                Showing{" "}
                {pondDetails.length === 0
                  ? indexOfFirstEntry
                  : indexOfFirstEntry + 1}{" "}
                to{" "}
                {indexOfLastEntry > pondDetails.length
                  ? pondDetails.length
                  : indexOfLastEntry}{" "}
                of {pondDetails.length} entries
              </div>
              <ul className="pagination mb-0 justify-content-center">
                <li
                  className={`page-item ${currentPage === 1 ? "disabled" : ""}`}
                >
                  <button
                    className="page-link"
                    onClick={() => handlePageChange(currentPage - 1)}
                  >
                    Previous
                  </button>
                </li>
                {[...Array(totalPages)].map((_, index) => (
                  <li
                    key={index}
                    className={`page-item ${index + 1 === currentPage ? "active" : ""
                      }`}
                  >
                    <button
                      className="page-link"
                      onClick={() => handlePageChange(index + 1)}
                    >
                      {index + 1}
                    </button>
                  </li>
                ))}
                <li
                  className={`page-item ${currentPage === totalPages ? "disabled" : ""
                    }`}
                >
                  <button
                    className="page-link"
                    onClick={() => handlePageChange(currentPage + 1)}
                  >
                    Next
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PondDetail;
