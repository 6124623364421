import React from 'react';
import { Link, Redirect } from 'react-router-dom';
import { AppSettings } from '../../config/app-settings.js';
import axios from 'axios';
import ShowError from '../../components/custom/custom.js';

class Register extends React.Component {
	static contextType = AppSettings;

	constructor(props) {
		super(props);

		this.handleSubmit = this.handleSubmit.bind(this);
		this.state = {
			redirect: false
		}
	}

	componentDidMount() {
		this.context.handleSetAppSidebarNone(true);
		this.context.handleSetAppHeaderNone(true);
		this.context.handleSetAppContentClass('p-0');
	}

	componentWillUnmount() {
		this.context.handleSetAppSidebarNone(false);
		this.context.handleSetAppHeaderNone(false);
		this.context.handleSetAppContentClass('');
	}

	async handleSubmit(event) {
		event.preventDefault();
		const firstName = event.target.txtFirstName.value;
		const lastName = event.target.txtLastName.value;
		const emailAddress = event.target.txtEmailAddress.value;
		const confirmEmailAddress = event.target.txtConfirmEmail.value;
		const password = event.target.txtPassword.value;
		const confirmPassword = event.target.txtConfirmPassword.value;
		const agreement = event.target.agreementCheckbox.checked;
		const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		if (firstName.length > 0 && lastName.length > 0) {
			if (emailAddress.length > 0 && emailRegex.test(emailAddress)) {
				if (emailAddress === confirmEmailAddress) {
					if (password === confirmPassword) {
						if (password.length >= 8) {
							if (agreement === true) {
								try {
									let API_URL = "";
									if (process.env.REACT_APP_Environment === 'development')
										API_URL = process.env.REACT_APP_API_URL;
									else
										API_URL = process.env.REACT_APP_API_URL_Production;

									await axios.post(API_URL + "api/fisher/Register", {
										FullName: firstName + " " + lastName,
										Email: emailAddress,
										Password: password,
										Status: false
									});
									window.location.reload();
									this.setState({ redirect: true });
								} catch (error) {
									console.log(error);
									let errorMessage = "An error occurred.";
									if (error.response && error.response.data && error.response.data.message) {
										errorMessage = error.response.data.message;
									}
									ShowError(errorMessage)
								}
							}
							else {
								ShowError("Please Agree with our terms and Conditions.")
							}

						}
						else {
							ShowError("Password should be Eight Characters long.")
						}


					}
					else {
						ShowError("Password and Confirm Password should be same.")
					}

				}
				else {
					ShowError("Email and Confirm Email should be Equal.")
				}
			}
			else {
				ShowError("Please Provide Valid Email Address.")
			}
		} else {
			ShowError("Please provide your First and Last Name.")
		}
	}

	render() {
		if (this.state.redirect) {
			return <Redirect to='/dashboard' />;
		}

		return (
			<div className="register register-with-news-feed">
				<div className="news-feed">
					<div className="news-image" style={{ backgroundImage: 'url(/assets/img/login-bg/login-bg-9.jpg)' }}></div>
					<div className="news-caption">
						<h4 className="caption-title"><b>SSF </b> FishinG</h4>
						<p>
							SSF Fishing is a leading platform in the fisheries industry, offering a comprehensive system to efficiently manage and optimize your fisheries operations</p>
					</div>
				</div>
				<div className="register-container">
					<div className="register-header mb-25px h1">
						<div className="mb-1">Sign Up</div>
						<small className="d-block fs-15px lh-16">Create your SSF Fishing Account.</small>
					</div>
					<div className="register-content">
						<form onSubmit={this.handleSubmit} className="fs-13px">
							<div className="mb-3">
								<label className="mb-2">Name <span className="text-danger">*</span></label>
								<div className="row gx-3">
									<div className="col-md-6 mb-2 mb-md-0">
										<input type="text" id='txtFirstName' className="form-control fs-13px" placeholder="First name" />
									</div>
									<div className="col-md-6">
										<input type="text" id='txtLastName' className="form-control fs-13px" placeholder="Last name" />
									</div>
								</div>
							</div>
							<div className="mb-3">
								<label className="mb-2">Email <span className="text-danger">*</span></label>
								<input type="text" id='txtEmailAddress' className="form-control fs-13px" placeholder="Email address" />
							</div>
							<div className="mb-3">
								<label className="mb-2">Re-enter Email <span className="text-danger">*</span></label>
								<input type="text" id='txtConfirmEmail' className="form-control fs-13px" placeholder="Re-enter email address" />
							</div>

							<div className="mb-4">
								<label className="mb-2">Password <span className="text-danger">*</span></label>
								<input type="password" id='txtPassword' className="form-control fs-13px" placeholder="Password" />
							</div>
							<div className="mb-4">
								<label className="mb-2">Confirm Password <span className="text-danger">*</span></label>
								<input type="password" id='txtConfirmPassword' className="form-control fs-13px" placeholder="Password" />
							</div>
							<div className="form-check mb-4">
								<input className="form-check-input" type="checkbox" value="" id="agreementCheckbox" />
								<label className="form-check-label" htmlFor="agreementCheckbox">
									By clicking Sign Up, you agree to our <Link to="#">Terms</Link> and that you have read our <Link to="#">Data Policy</Link>, including our <Link to="#">Cookie Use</Link>.
								</label>
							</div>
							<div className="mb-4">
								<button type="submit" className="btn btn-primary d-block w-100 btn-lg h-45px fs-13px">Sign Up</button>
							</div>
							<div className="mb-4 pb-5">
								Already a member? Click <Link to="/user/login">here</Link> to login.
							</div>
							<hr className="bg-gray-600 opacity-2" />
							<p className="text-center text-gray-600">
								&copy; SSF Fishing. All Rights Reserved
							</p>
						</form>
					</div>
				</div>
			</div>
		)
	}
}

export default Register;